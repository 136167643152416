<template>
    <section class="invoice-preview-wrapper">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="12" md="8">
                    <b-card no-body class="invoice-actions">
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="2">
                                    <label for="date_start">Fecha Inicio</label>
                                    <flat-pickr id="date_start" v-model="filter.fechaInicio"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-col>
                                <b-col cols="12" md="2">
                                    <label for="date_start">Fecha Fin</label>
                                    <flat-pickr id="date_start" v-model="filter.fechaFin"
                                        class="form-control flatpickr-small" :config="{ dateFormat: 'Y-m-d' }"
                                        placeholder="DD/MM/YYYY" />
                                </b-col>

                                <b-col cols="12" md="4">
                                    <div class="demo-inline-spacing">
                                        <b-button size="sm" @click="getDetailsVsOldSystem" variant="primary"
                                            v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                            <feather-icon icon="SearchIcon" />
                                            Buscar
                                        </b-button>

                                        <b-button size="sm" v-if="items" @click="exportToExcel"
                                            variant="secondary" v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                            <feather-icon icon="DownloadIcon" />
                                            Descargar
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="8">
                    <b-card no-body class="mb-0">

                        <!-- <div class="m-2">
                            <b-row>
                                <b-col cols="12">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <b-input-group>
                                            <b-form-input autofocus autocomplete="off" size="sm" v-model="searchQuery" placeholder="Buscar por folio, factura id, articulo"/>
                                        </b-input-group>
                                    </div>                                    
                                </b-col>
                            </b-row>
                        </div> -->

                        <div class="table-responsive">
                            <div class="position-relative b-table-responsive mb-0 text-small small b-table-sticky-header table-responsive">
                            <table ref="routings" class="table b-table text-small small table-small table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                                <thead>
                                    <tr>
                                        <th style="font-size: 10px!important;">Folio</th>
                                        <th style="font-size: 10px!important;">Factura ID</th>                                        
                                        <th style="font-size: 10px!important;">Fecha Salida Carrito</th>
                                        <th style="font-size: 10px!important;">Fecha Salida App</th>
                                        <th style="font-size: 10px!important;">Articulo</th>
                                        <th style="font-size: 10px!important;">Cant. Total</th>
                                        <th style="font-size: 10px!important;">Cant. App</th>
                                        <th style="font-size: 10px!important;">Cant. Carrito</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item, index) in items" :key="index">
                                    <tr>
                                        <td colspan="8" style="font-size: 10px!important;"><strong>{{ index }}</strong></td>
                                    </tr>
                                    <tr v-for="(i, idx) in item" :key="index + idx">
                                        <td style="font-size: 10px!important;"></td>                                        
                                        <td style="font-size: 10px!important;">{{ i.docto_Pv_Id }}</td>
                                        <td style="font-size: 10px!important;">{{ formatDate(i.fecha_Salida_Carrito) }}</td>
                                        <td style="font-size: 10px!important;">{{ formatDate(i.fecha_Alta) }}</td>
                                        <td style="font-size: 10px!important;">{{ i.articulo_Nombre }}</td>
                                        <td style="font-size: 10px!important;">{{ i.unidad_Medida_Total }}</td>
                                        <td style="font-size: 10px!important;">{{ i.unidades }}</td>
                                        <td style="font-size: 10px!important;">{{ i.cantidad_Carrito }}</td>
                                    </tr>                                   
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

        </b-overlay>
    </section>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import * as XLSX from 'xlsx';
import moment from 'moment';

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes';
const DeliveryRoutesResource = new DeliveryRoutesProvider();

export default {
    name: "RoutingLogs",
    components: {
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            searchQuery: '',
        }
    },
    computed: {
        filterData() {
            let result = this.searchQuery ? this.items.filter((item) => {
                return (
                    item.factura_Folio?.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    item.docto_Pv_Id?.toString().includes(this.searchQuery.toLowerCase()) ||
                    item.articulo_Nombre?.toLowerCase().includes(this.searchQuery.toLowerCase())
                );
            }) :  this.items;

            return _.groupBy(result, 'factura_Folio')
        },
    },
    created() {

    },
    methods: {
        exportToExcel() {
            const table = this.$refs.routings;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Salidas-App-Carrito.xlsx');
            },
    },
    setup() {

        const loading = ref(false)
        const search = ref(null)

        const filter = ref({
            fechaInicio: moment().startOf('month').format('YYYY-MM-DD'),
            fechaFin: moment().format('YYYY-MM-DD'),
        })

        // Column Definitions: Defines the columns to be displayed.
        const columns = ref([]);
        const items = ref([])


        const getDetailsVsOldSystem = async () => {
            items.value = []
            try {
                loading.value = true
                const { data } = await DeliveryRoutesResource.getDetailsVsOldSystem(filter.value)
                loading.value = false
                items.value = _.groupBy(data.data, 'factura_Folio')
                console.log(items.value)
            } catch (error) {
                loading.value = false
            }
        }

        const printReport = () => {
            window.print()
        }

        // Computed: Filter Prices

        return {
            search,
            loading,
            items,
            filter,

            //Methods
            getDetailsVsOldSystem,
            printReport,
        };
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
        font-size: 8pt !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {



        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>

<style>
.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px;
    /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px !important;
    /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}
</style>